import React from 'react';

const FindUs = () => {
  return (
    <>
      <div className="w-full pt-16 pb-16 border-t border-grey-c">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="text-2xl">
            <h3>Find us</h3>
          </div>
        </div>
      </div>
      <div className="w-full pb-16">
        <iframe title="location" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14724.3641703244!2d114.1587772!3d22.6876545!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3404767532da8aa9%3A0x5b93edf9d3a24108!2zMjLCsDQxJzI2LjUiTiAxMTTCsDEwJzA0LjAiRQ!5e0!3m2!1sen!2snz!4v1661888364739!5m2!1sen!2snz" width="100%" height="450" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </>
  );
}

export default FindUs;
